.important-instructions {
  display: flex;
  flex-direction: row;
  padding-top: 60px;

  &__intro {
    font-weight: 500;
    color: $red;
  }
}
