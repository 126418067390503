/**** Font Decleration************/
$fontRegular: "./Roboto-Regular.ttf";
$fontBold: "./Roboto-Bold.ttf";
$fontThin: "./Roboto-Thin.ttf";
$fontLight: "./Roboto-Light.ttf";
// Change path to Roboto fonts to fix build process

@font-face {
  font-family: Roboto;
  src: url($fontBold) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url($fontRegular) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url($fontThin) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url($fontLight) format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: Roboto, sans-serif !important;
  font-weight: 300 normal !important;
  -webkit-overflow-scrolling: touch;
  font-size: 1.125rem !important;  
}
