.custom-btn {
    margin: 0 auto;
    border: 1px solid #dfdfdf;
    box-shadow: 2px 2px #949292;
    font-size: 1.125rem !important;
    background: #0080b9;
    color: #fff;
}

.cus-outer-div{
    display: flex !important;
}

.center-header{
    text-align: center;
}
