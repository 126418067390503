
.rt-sec{width: 100%; margin-top: 45px;    }
.rt-2 {  float: left;
    width: 48%;     margin-left: 1%; margin-right: 1%;}
	
	.rt-3 {  float: left;
    width: 31.3%;     margin-left: 1%; margin-right: 1%;}
.rt {    height: 60px;  background-image: linear-gradient(to right, #f5f5f9 0%, #edeef1 20%, #f5f5f9 40%, #f5f5f9 100%); background-repeat: no-repeat;background-size: 800px auto; background-color: #f5f5f9; animation: shine 3s infinite;}	
.btn-f {width: 600px; 	  margin:45px auto}
.rt-21 {  float: left;
    width: 200px;     margin-left: 30px; margin-right: 30px;}
	
.rt-4 {float: left; width: 23%; margin-left: 1%; margin-right: 1%;}
.rt-6 {float: left; width: 14.6%; margin-left: 1%; margin-right: 1%;}
.rt-third{width: 100%; margin-top: 30px;    }
.rtn {    height: 8em; border-radius: 4%; background-image: linear-gradient(to right, #f5f5f9 0%, #edeef1 20%, #f5f5f9 40%, #f5f5f9 100%); background-repeat: no-repeat;background-size: 800px auto; background-color: #f5f5f9; animation: shine 3s infinite;}	
.head {     margin-left: 1%; margin-top: 55px; height: 50px; 
   // width: 10%;
     background-image: linear-gradient(to right, #f5f5f9 0%, #edeef1 20%, #f5f5f9 40%, #f5f5f9 100%); background-repeat: no-repeat;background-size: 800px auto; background-color: #f5f5f9; animation: shine 3s infinite;}
.form-c {padding-bottom: 40px}