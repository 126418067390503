.post-logout-container {
    padding: 20px;
    margin: 50px auto;
    text-align: center;
}

.post-logout-title {
    color: #005bb0;
    font-size: 24px;
    margin-bottom: 10px;
}

.post-logout-message {
    font-size: 16px;
}